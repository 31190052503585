import { PortableTextTypeComponent } from "@portabletext/react";
import { Dice as DiceType } from "@/cms/types/objects";
import { TypedObject } from "@portabletext/types";
import { DiceQuote } from "@/components/Dice";

export const DiceQuoteBlock: PortableTextTypeComponent<
  DiceType & TypedObject
> = ({ value }) => {
  return <DiceQuote value={value} />;
};
