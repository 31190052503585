import { CSSProperties } from "react";
import { ImageTheme, Theme } from "./types";

const NAMES = ["primary", "secondary", "tertiary", "quaternary"] as const;

export const getCSSPropertiesTheme = (
  ...args: (Theme | undefined | null)[]
): CSSProperties =>
  args.reduce<CSSProperties>((acc, cur, index) => {
    if (!cur) return acc;
    const { background, text } = cur;
    return {
      ...acc,
      [`--bg-${NAMES[index]}`]: background?.hex,
      [`--fg-${NAMES[index]}`]: text?.hex,
    };
  }, {});

export const getCSSPropertiesImageTheme = (
  ...args: (ImageTheme | undefined | null)[]
): CSSProperties =>
  args.reduce<CSSProperties>((acc, cur, index) => {
    if (!cur) return acc;
    const { background, text, theme } = cur;
    return {
      ...acc,
      [`--bg-${NAMES[index]}`]: background ?? theme?.background?.hex,
      [`--fg-${NAMES[index]}`]: text ?? theme?.text?.hex,
    };
  }, {});

export const getStyleFromImageThemeOrExistingTheme = (
  themeBasedOnFirstSlide: ImageTheme | undefined | null,
  property: string,
): string | undefined => {
  if (!themeBasedOnFirstSlide) {
    return;
  }
  return (themeBasedOnFirstSlide.theme?.[property as keyof Theme]?.hex ??
    (themeBasedOnFirstSlide as any)[property]) as string | undefined;
};
