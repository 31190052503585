import { PortableTextTypeComponent } from "@portabletext/react";
import { ImageBlock as ImageBlockType } from "../../cms/types/objects";
import { TypedObject } from "@portabletext/types";
import { ImageBlock } from "../../components/ImageBlock";

export const ImageBoxBlock: PortableTextTypeComponent<
  ImageBlockType & TypedObject
> = ({ value }) => {
  return <ImageBlock value={value} />;
};
