import { ImageUrlBuilderOptions } from "@sanity/image-url/lib/types/types";
import { ImageProps } from "next/image";
import { imageUrlFor } from "./imageBuilder";
import { SanityImage } from "./types";

/**
 * @deprecated This is a work  around for the image typegen.
 * Should be removed in all cases by using SSR and <SanityImage/> instead.
 */
export const getSanityImageFromTypedImage = <G>(image: G) => {
  return image as SanityImage;
};

export const getImageProps = ({
  asset,
  hotspot,
  crop,
  altText,
  width = 1920,
  height = getImageAspectRatio({ width: 1920, aspectRatio: "16/9" }).height,
  unoptimized = true,
  ...options
}: SanityImage & Omit<Partial<ImageUrlBuilderOptions>, "crop">): Required<
  Pick<ImageProps, "src" | "alt" | "placeholder" | "width" | "height">
> &
  Pick<ImageProps, "blurDataURL" | "unoptimized"> => ({
  src: imageUrlFor({ asset, crop, hotspot })
    .withOptions(options)
    .width(width)
    .height(height)
    .fit("crop")
    .format("webp")
    .auto("format")
    .url(),
  alt: altText ?? asset?.altText,
  placeholder: "blur",
  blurDataURL: asset?.metadata?.lqip,
  width,
  height,
  unoptimized,
});

type AspectRatio =
  | { height?: number; width?: null }
  | { height?: null; width?: number };

export const getImageAspectRatio = ({
  height = 0,
  width = 0,
  aspectRatio = "16/9",
}: {
  aspectRatio?: "16/9" | "1/1" | "4/3" | number;
} & AspectRatio) => {
  const ratio =
    typeof aspectRatio === "number"
      ? aspectRatio
      : aspectRatio
          .split("/")
          .reduce((acc, cur) => (acc ? acc / parseInt(cur) : parseInt(cur)), 0);
  if (height)
    return {
      height,
      width: Math.round(height * ratio),
    };
  if (width)
    return {
      width,
      height: Math.round(width / ratio),
    };

  return {
    height: 0,
    width: 0,
  };
};
