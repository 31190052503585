import { PortableTextTypeComponent } from "@portabletext/react";
import { Quote as QuoteType } from "@/cms/types/objects";
import { TypedObject } from "@portabletext/types";
import { Quote } from "@/components/Quote";

export const QuoteBlock: PortableTextTypeComponent<QuoteType & TypedObject> = ({
  value,
}) => {
  return <Quote value={value} />;
};
