import { PortableTextTypeComponent } from "@portabletext/react";
import { TypedObject } from "@portabletext/types";
import CMSLink from "@/cms/links/components/CMSLink";
import { LinkQueryResult } from "@/cms/sanity.types";

export const LinkBlock: PortableTextTypeComponent<
  LinkQueryResult & TypedObject
> = ({ value }) => {
  return (
    <CMSLink
      data={value}
      parentIndex={0}
      parentSection=""
      itemIndex={0}
      className="inline-flex w-fit self-center sm:self-start"
    />
  );
};
