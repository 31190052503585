import {
  DiceOne,
  DiceFive,
  DiceFour,
  DiceSix,
  DiceThree,
  DiceTwo,
  Square,
} from "@phosphor-icons/react/dist/ssr";
import type { IconProps } from "@phosphor-icons/react";
import type { FC } from "react";
import { Dice as DiceTypes } from "@/cms/types/objects";
import classNames from "classnames";

export const Dice: FC<
  { value: 1 | 2 | 3 | 4 | 5 | 6 | number | null } & IconProps
> = ({ value, ...rest }) => {
  switch (value) {
    case 1:
      return <DiceOne aria-label="Terningkast 1" {...rest} />;
    case 2:
      return <DiceTwo aria-label="Terningkast 2" {...rest} />;
    case 3:
      return <DiceThree aria-label="Terningkast 3" {...rest} />;
    case 4:
      return <DiceFour aria-label="Terningkast 4" {...rest} />;
    case 5:
      return <DiceFive aria-label="Terningkast 5" {...rest} />;
    case 6:
      return <DiceSix aria-label="Terningkast 6" {...rest} />;
    default:
      return <Square {...rest} />;
  }
};

export const getDiceValue = (string: string) => {
  if (string === "DiceSix") return 6;
  if (string === "DiceFive") return 5;
  if (string === "DiceFour") return 4;
  return null;
};

export type DiceQuoteProps = {
  value: DiceTypes;
};

export const DiceQuote: FC<
  DiceQuoteProps & JSX.IntrinsicElements["blockquote"]
> = ({ value: { icon, text, textAlignment }, className, ...rest }) => {
  return (
    <blockquote
      className={classNames(className, "mx-auto flex w-fit gap-x-2 sm:mx-0")}
      data-testid="dice"
      {...rest}
    >
      <span
        className={classNames("inline-block w-fit self-center sm:text-left")}
      >
        {text}
      </span>
      <Dice
        className={classNames("inline-block min-w-fit self-center", {
          "order-first": textAlignment === "after",
        })}
        value={getDiceValue(icon)}
        size={44}
        weight="fill"
      />
    </blockquote>
  );
};
