import { Anchor, AnchorProps } from "./Anchor";
import classNames from "classnames";

export const Link = ({ className, children, ...rest }: AnchorProps) => {
  return (
    <Anchor
      {...rest}
      className={classNames(
        "inline-flex items-center gap-1",
        "underline underline-offset-8",
        "hover:no-underline focus:rounded-lg focus:border-4 focus:border-black",
        className,
      )}
    >
      {children}
    </Anchor>
  );
};
