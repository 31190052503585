import { PortableTextTypeComponent } from "@portabletext/react";
import { IconList } from "@/components/IconList";
import { IconList as IconListType } from "@/cms/sanity.types";
import { TypedObject } from "@portabletext/types";

export const IconListBlock: PortableTextTypeComponent<
  IconListType & TypedObject
> = ({ value }) => {
  return <IconList value={value} className="flex-wrap" />;
};
