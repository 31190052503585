import { Theme } from "@/theming/types";
import { getCSSPropertiesTheme } from "@/theming/getCSSProperties";
import { CSSProperties } from "react";
import { Anchor, AnchorProps } from "./Anchor";
import classNames from "classnames";

export const LinkButton = ({
  theme,
  className,
  style,
  children,
  ...rest
}: AnchorProps & { theme?: Theme }) => {
  return (
    <Anchor
      {...rest}
      className={classNames(
        "bg-primary cvr-button border-2 border-[unset] text-[var(--fg-primary)] !no-underline shadow-none duration-150 ease-in-out hover:border-2 hover:bg-[var(--hover)] hover:text-[var(--fg-primary)]",
        {
          "border-primary-contrast": theme,
          "text-primary-contrast border-2 border-[unset] shadow-none hover:border-2 hover:bg-[var(--hover)] hover:text-[unset]":
            !theme,
        },
        className,
      )}
      style={
        {
          "--text-hover": `color-mix(in srgb, var(--text-primary-contrast) 80%, black)`,
          "--hover": `color-mix(in srgb, var(--bg-primary) 80%, black)`,
          ...(theme ? getCSSPropertiesTheme(theme) : {}),
          ...style,
        } as CSSProperties
      }
    >
      {children}
    </Anchor>
  );
};
