import { FC } from "react";
import classNames from "classnames";

export const ImageCredit: FC<{
  credit?: string | null;
  className?: string;
  id?: string;
}> = ({ credit, className, id }) => {
  if (!credit) return null;
  return (
    <div
      id={id}
      className={classNames("text-sm", className)}
    >{`Foto: ${credit}`}</div>
  );
};
