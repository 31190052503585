import { getPageLink } from "@/navigation/selectors";
import { LinkQueryResult } from "../sanity.types";
import { Nullable } from "@/types";

export const getPageLinkFromLinkConfig = ({
  link,
  universeTag,
}: {
  link?: Nullable<
    Pick<
      NonNullable<LinkQueryResult>,
      "slug" | "external" | "type" | "cartItems"
    >
  > | null;
  universeTag?: string | null;
}) => {
  const { params, searchParams } = getCartItemsUrl(link?.cartItems);
  return getPageLink({
    pageType: link?.type ?? "home",
    url: link?.external,
    params: link?.slug || params,
    searchParams,
    universeTag,
  });
};

export const getCartItemsUrl = (
  cartItems?: NonNullable<LinkQueryResult>["cartItems"] | null,
) => {
  const subscriptionSku = cartItems?.find(
    (item) => item._type === "subscription",
  )?.sku;

  const searchParams = cartItems?.reduce<{
    [key: string]: string | string[] | undefined;
  }>((acc, cur) => {
    if (cur._type === "subscription") return acc;
    return { ...acc, item: acc.item ? [...acc.item, cur.sku] : [cur.sku] };
  }, {});

  return {
    params: subscriptionSku,
    searchParams,
  };
};
